/**
 * OOI-specific experiments should be listed here.
 * scope: viewer-apps-14bcded7-0066-7c35-14d7-466cb3f09103
 */
export const OOI_EXPERIMENTS = {
  /**
   * https://bo.wix.com/guineapig/experiments/3508196
   */
  INSTALL_SEARCH: 'specs.wixBlog.InstallSearch',
  /**
   * https://bo.wix.com/guineapig/experiments/3627662
   */
  USE_PLATFORM_API: 'specs.wixBlog.migratedToNewPlatformApi',
  /** https://bo.wix.com/guineapig/experiments/2999137 */
  MANAGE_POSTS_NAVIGATE_TO_POSTS: 'specs.wixBlog.ManagePostsNavigateToPosts',
  /** https://bo.wix.com/guineapig/experiments/3727397 */
  SLOTS_IN_POST_PAGE: 'specs.wixBlog.SlotsInPostPage',

  /**
   * https://bo.wix.com/guineapig/experiments/3702546
   */
  USE_NEW_CATEGORY_MENU_SETTINGS_URL:
    'specs.wixBlog.useNewCategoryMenuSettingsUrl',

  /**
   * https://bo.wix.com/guineapig/experiments/3853865
   */
  USE_NEW_ARCHIVE_WIDGET_SETTINGS_URL:
    'specs.wixBlog.useNewArchiveWidgetSettingsUrl',
  /**
   * https://bo.wix.com/guineapig/experiments/3872804
   */
  USE_NEW_RSS_BUTTON_SETTINGS_URL: 'specs.wixBlog.UseNewRssButtonSettingsUrl',
  /**
   * - All users: https://bo.wix.com/guineapig/experiments/3911655
   * - Employees: https://bo.wix.com/guineapig/experiments/3911654
   * - ClientSpecMap: https://bo.wix.com/client-spec-map-experiment-server/experiments/specs.wixBlog.TagCloudCssPerBreakpoint/14bcded7-0066-7c35-14d7-466cb3f09103
   */
  TAG_CLOUD_CSS_PER_BREAKPOINT: 'specs.wixBlog.TagCloudCssPerBreakpoint',
};
